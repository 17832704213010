import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { useIntl } from 'gatsby-plugin-intl'
import { Dialog } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  carouselContainer: {
    width: '100vw',
    maxHeight: '100vh',
    overflowX: 'hidden',
    position: 'relative',
  },

  closeBtn: {
    cursor: 'pointer',
    background: 'white',
    fontWeight: 'bold',
    border: 'none',
    position: 'absolute',
    right: '1rem',
    top: '3rem',
    padding: '1rem',
    zIndex: 110,
  },
}))

interface Props {
  isOpen: boolean
  close: () => void
  children: any
}
const ComponentDialog = ({ children }) => <div style={{ display: 'flex' }}>{children}</div>

const PropertyDialog: React.FC<Props> = ({ isOpen, close, children }) => {
  const classes = useStyles()
  const intl = useIntl()

  return (
    <Dialog fullScreen open={isOpen} onClose={close} PaperComponent={ComponentDialog}>
      <div className={classes.carouselContainer}>
        <button onClick={close} className={classes.closeBtn}>
          x {intl.formatMessage({ id: 'close' })}
        </button>
        {children}
      </div>
    </Dialog>
  )
}

export default PropertyDialog
