import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Launch } from '@material-ui/icons'
import { Hidden } from '@material-ui/core'

import { useIntl } from 'gatsby-plugin-intl'
import { Button } from '@material-ui/core'

const useStyles: any = makeStyles((theme) => ({
  launch: {
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(14),
    },
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  button: {
    marginTop: '-1px',
    fontWeight: 400,
    textTransform: 'initial',
  },
  link: {
    fontWeight: 400,
    textTransform: 'initial',
    textDecoration: 'none',
    color: 'black',
  },
}))

export const mailtoLinkShareWithFriend = (reference: string, title: string, intl: any) =>
  `mailto:?subject=KRETZ (${reference}) - ${title}&body=${encodeURIComponent(
    intl.formatMessage({ id: 'socialShare.email.hello' }) +
      '\n\n' +
      intl.formatMessage({ id: 'socialShare.email.body' }) +
      '\n' +
      (typeof window !== 'undefined' ? window.location.toString() : 'https://kretzrealestate.com') +
      '\n\n' +
      intl.formatMessage({ id: 'socialShare.email.separator' }),
  )}`

export default function ModalShare({ reference = '', title = '' }) {
  const intl = useIntl()
  const classes = useStyles()

  return (
    <Button
      className={classes.button}
      size="small"
      color="primary"
      startIcon={
        <a
          href={mailtoLinkShareWithFriend(reference, title, intl)}
          target={'_blank'}
          className={classes.link}
        >
          <Launch className={classes.launch} />
        </a>
      }
      onClick={() => 'done'}
    >
      <a
        href={mailtoLinkShareWithFriend(reference, title, intl)}
        target={'_blank'}
        className={classes.link}
      >
        {intl.formatMessage({ id: 'sendToFriend' })}
      </a>
    </Button>
  )
}
