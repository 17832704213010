export const numberWithSpacesAndCurrency = (
  x: any,
  currency: string | undefined,
  compact: boolean = false,
) => {
  if (isNaN(x)) {
    return x
  }

  let currSymbol
  switch (currency) {
    case 'EUR':
      currSymbol = '€'
      break
    case 'DOL':
      currSymbol = '$'
      break
    case 'GBP':
      currSymbol = '£'
      break
    case 'CHF':
      currSymbol = '₣'
      break
    case 'AED':
      currSymbol = 'AED'
      break
    case 'BRL':
      currSymbol = 'R$'
      break
    default:
      currSymbol = '€'
  }

  const parts = (x || 0).toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return !compact
    ? `${parts.join('.')} ${currSymbol}`
    : `${Intl.NumberFormat('en', { notation: 'compact' }).format(x)} ${currSymbol}`
}

export const getDisplayedPrice = (
  price: any,
  priceMax: any,
  unitPrice: any,
  isConfidential: any,
  confidentialSentence: any,
  isRented: any,
  isSeasonal: any,
  suffixRent: any,
  suffixSeasonal: any,
  from: any,
  to: any,
  isCompact: boolean = false,
) => {
  let displayedPrice: String | null
  switch (true) {
    case isConfidential:
      displayedPrice = confidentialSentence
      break

    case isSeasonal:
      displayedPrice = `${from} ${numberWithSpacesAndCurrency(
        price,
        unitPrice,
      )} ${to} ${numberWithSpacesAndCurrency(priceMax, unitPrice)}${suffixSeasonal}`
      break

    case isRented:
      displayedPrice = `${numberWithSpacesAndCurrency(price, unitPrice)}${suffixRent}`
      break

    default:
      displayedPrice = `${numberWithSpacesAndCurrency(price, unitPrice)}`
      break
  }

  return displayedPrice
}
