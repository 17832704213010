import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'gatsby-plugin-intl'
import { getDisplayedPrice, numberWithSpacesAndCurrency } from '../../localization/number-formatter'
import { STATUS_A_LOUER, STATUS_SAISONNIER } from '../../utils/constants'
import PropertyExtraDetailsDPESliders from './PropertyExtraDetailsDPESliders'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
} from '@material-ui/core'
import { Check, ExpandMore } from '@material-ui/icons'
import ModalShare from '../ModalShare'
import { Button } from '@material-ui/core'
import { generatePdf } from '../../utils/submitForm'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  container: {},
  extraTitle: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.between('xs', 'sm')]: {
      marginBottom: theme.spacing(3),
      fontSize: theme.typography.pxToRem(13),
    },
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(15),
    },
  },
  dpeGraph: {
    [theme.breakpoints.between('xs', 'sm')]: {
      marginBottom: theme.spacing(0),
    },
    [theme.breakpoints.between('md', 'md')]: {
      marginTop: theme.spacing(3),
    },
  },
  divider: {
    borderBottom: '1px solid #707070',
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(5),
  },
  details: {
    lineHeight: 1.7,
    letterSpacing: '0em',
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(13),
    },
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  detailsValue: {
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'right',
    },
  },
  title: {
    fontWeight: 'bold',
  },

  button: {
    fontWeight: 400,
    textTransform: 'initial',
  },

  launch: {
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(12),
    },
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  panelSummary: {
    '&.Mui-expanded': {
      minHeight: 0,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 'auto',
    },
  },
}))

export const PropertyExtraDetails: React.FC<any> = ({ data }: any) => {
  const classes = useStyles()
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const booleanToString = (bool: boolean) =>
    bool ? intl.formatMessage({ id: 'yes' }) : intl.formatMessage({ id: 'no' })

  const {
    title,
    airConditionne,
    alarme,
    ascenseur,
    autreVille,
    bienEnCopro,
    coproEnProcedure,
    honorairesVente,
    infos,
    isOffmarket,
    lat,
    lng,
    nombreChambres,
    nombreLotsCopro,
    nombrePieces,
    nombreSallesDeBain,
    photos,
    piscine,
    pk,
    prixAffichageFai,
    prixConfidentiel,
    prixLoyer,
    prixSaisonnierMax,
    prixSaisonnierMin,
    propertyType,
    propertyTypeDisplay,
    reference,
    statutVente,
    suiviPar,
    surfaceHabitable,
    titreFr,
    unitArea,
    unitPrice,
    video,
    ville,
    visiteVirtuelle,
  } = data

  const Item = ({ label, value }: any) => (
    <Grid item xs={12} container>
      <Grid item xs={6} className={classes.title}>
        {intl.formatMessage({ id: label })}
      </Grid>
      <Grid item xs={6} className={classes.detailsValue}>
        {value}
      </Grid>
    </Grid>
  )

  const [width, setWidth] = useState<number>(0)

  useEffect(() => {
    setWidth(window.innerWidth)

    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  return (
    <Grid className={classes.root} container justifyContent="center">
      <Grid item xs={8} className={classes.container}>
        <Grid container item>
          <Grid item xs={12} md={4} className={classes.extraTitle}>
            {intl.formatMessage({ id: 'characteristics' })}
          </Grid>
          <Grid item container xs={12} md={8} className={classes.details}>
            <Item
              label="price"
              value={getDisplayedPrice(
                STATUS_A_LOUER.includes(statutVente)
                  ? prixLoyer
                  : STATUS_SAISONNIER.includes(statutVente)
                  ? prixSaisonnierMin
                  : prixAffichageFai,
                STATUS_SAISONNIER.includes(statutVente) ? prixSaisonnierMax : null,
                unitPrice,
                prixConfidentiel,
                intl.formatMessage({ id: 'confidentialPrice' }),
                STATUS_A_LOUER.includes(statutVente),
                STATUS_SAISONNIER.includes(statutVente),
                intl.formatMessage({ id: 'perMonths' }),
                intl.formatMessage({ id: 'perWeeks' }),
                intl.formatMessage({ id: 'fromshort' }),
                intl.formatMessage({ id: 'toshort' }),
                false,
              )}
            />
            <Item
              label="propertyType"
              value={intl.formatMessage({ id: 'propertyTypeEnum.' + propertyType })}
            />
            <Item label="city" value={ville} />
            <Item
              label="area"
              value={`${surfaceHabitable}${
                unitArea == 'M2'
                  ? intl.formatMessage({ id: 'squareMeter' })
                  : intl.formatMessage({ id: 'squareFeet' })
              }`}
            />
            <Item label="pieces" value={nombrePieces} />
            <Item
              label={nombreChambres && nombreChambres > 1 ? 'chambers' : 'chamber'}
              value={nombreChambres}
            />
            <Item label="bathroom" value={nombreSallesDeBain} />
            <Item label="ascenseur" value={booleanToString(ascenseur)} />
            <Item label="alarme" value={booleanToString(alarme)} />
            <Item label="airConditionne" value={booleanToString(airConditionne)} />
            <Item label="piscine" value={booleanToString(piscine)} />
          </Grid>
        </Grid>
        <Box className={classes.divider} />
        {!STATUS_SAISONNIER.includes(statutVente) && (
          <>
            <Grid container item>
              <Grid item xs={12} md={4} className={classes.extraTitle}>
                {intl.formatMessage({ id: 'legalInfo' })}
              </Grid>
              <Grid item container xs={12} md={8} className={classes.details}>
                <Grid item xs={12} container>
                  <Grid item xs={6} className={classes.title}>
                    {intl.formatMessage({ id: 'coproEnProcedure' })}
                  </Grid>
                  <Grid item xs={6} className={classes.detailsValue}>
                    {booleanToString(coproEnProcedure)}
                  </Grid>
                </Grid>
                <Grid item xs={12} container>
                  <Grid item xs={6} className={classes.title}>
                    {intl.formatMessage({ id: 'bienEnCopro' })}
                  </Grid>
                  <Grid item xs={6} className={classes.detailsValue}>
                    {booleanToString(bienEnCopro)}
                  </Grid>
                </Grid>
                <Grid item xs={12} container>
                  <Grid item xs={6} className={classes.title}>
                    {intl.formatMessage({ id: 'nombreLotsCopro' })}
                  </Grid>
                  <Grid item xs={6} className={classes.detailsValue}>
                    {nombreLotsCopro}
                  </Grid>
                </Grid>
                {intl.locale && intl.locale.toLowerCase() != 'es' && (
                  <Grid item xs={12} container>
                    <Grid item xs={6} className={classes.title}>
                      {intl.formatMessage({ id: 'honorairesVente' })}
                    </Grid>
                    <Grid item xs={6} className={classes.detailsValue}>
                      {honorairesVente
                        ? numberWithSpacesAndCurrency(honorairesVente, unitPrice, false)
                        : ''}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Box className={classes.divider} />
          </>
        )}
        {!STATUS_SAISONNIER.includes(statutVente) ? (
          <>
            <Grid item xs={12} md={12} container>
              <Grid
                item
                xs={12}
                md={12}
                className={classes.extraTitle}
                style={{ paddingBottom: '15px' }}
              >
                {intl.formatMessage({ id: 'labelDiagnosticPerformanceEnergetique' })}
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} className={classes.dpeGraph}>
              <PropertyExtraDetailsDPESliders data={data} />
            </Grid>
          </>
        ) : (
          <>
            <Grid container item>
              <Grid
                item
                xs={12}
                md={12}
                style={{ fontWeight: 'bolder' }}
                className={classes.extraTitle}
              >
                {intl.formatMessage({ id: 'titleConciergerie' })}
              </Grid>
              <Grid item xs={12} md={12} className={classes.title}>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <Check />
                    </ListItemIcon>
                    <ListItemText
                      primary={intl.formatMessage({ id: 'itemConciergerie_1' })}
                      secondary={null}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Check />
                    </ListItemIcon>
                    <ListItemText
                      primary={intl.formatMessage({ id: 'itemConciergerie_2' })}
                      secondary={null}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Check />
                    </ListItemIcon>
                    <ListItemText
                      primary={intl.formatMessage({ id: 'itemConciergerie_3' })}
                      secondary={null}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Check />
                    </ListItemIcon>
                    <ListItemText
                      primary={intl.formatMessage({ id: 'itemConciergerie_4' })}
                      secondary={null}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Check />
                    </ListItemIcon>
                    <ListItemText
                      primary={intl.formatMessage({ id: 'itemConciergerie_5' })}
                      secondary={null}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Box className={classes.divider} />
            <Grid container item>
              <Grid item xs={12} md={12} className={classes.extraTitle}>
                {intl.formatMessage({ id: 'titleBooking_1' })}
              </Grid>
              <List>
                <ListItem>
                  <Grid item xs={12} md={12}>
                    <Accordion elevation={0}>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="subTitleBooking_1"
                        id="panel1a-header"
                        className={classes.panelSummary}
                      >
                        <Typography variant="subtitle1">
                          {intl.formatMessage({ id: 'subTitleBooking_1' })}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {intl.formatMessage({ id: 'subTextBooking_1_1' })}
                          <br />
                          {intl.formatMessage({ id: 'subTextBooking_1_2' })}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid item xs={12} md={12}>
                    <Accordion elevation={0}>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="subTitleBooking_2"
                        className={classes.panelSummary}
                        id="subTitleBooking_2"
                      >
                        <Typography variant="subtitle1">
                          {intl.formatMessage({ id: 'subTitleBooking_2' })}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {intl.formatMessage({ id: 'subTextBooking_2_1' })}
                          <br />
                          {intl.formatMessage({ id: 'subTextBooking_2_2' })}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </ListItem>
              </List>
            </Grid>
          </>
        )}
        {isMobile && (
          <Grid
            container
            direction="row"
            style={{ marginTop: '22px', marginBottom: '-50px' }}
            justifyContent="center"
          >
            <Grid xs={10} style={{ width: 'max-content' }} className={classes.launch}>
              <ModalShare reference={!isOffmarket ? reference : ''} title={title} />
            </Grid>
            <Grid
              xs={10}
              style={{ width: 'max-content' }}
              justifyContent="center"
              className={classes.launch}
            >
              <Button
                className={classes.button}
                size="small"
                color="primary"
                startIcon={<PictureAsPdfIcon className={classes.launch} />}
                onClick={() => generatePdf(pk, reference)}
              >
                {intl.formatMessage({ id: 'downloadTheFile' })}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default PropertyExtraDetails
