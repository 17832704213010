type destinationType = {
  name: string
  translation: string
  img: string
  sliderImg: string
  alt: string
  type: string
  key: string
  btnTxt: string
}

export const STATUS_A_LOUER = ['ALO_A']
export const STATUS_SAISONNIER = ['SAI_A']

export const DESTINATIONS: {
  [key: string]: destinationType
} = {
  'paris-je-taime': {
    name: 'paris',
    key: 'DDR_PARIS',
    translation: 'paris',
    img: 'dd1.png',
    sliderImg: 'DDR_Paris.jpg',
    alt: 'paris',
    type: 'Paris',
    btnTxt: 'ourHouseInParis',
  },
  'big-in-usa': {
    name: 'usa',
    key: 'DDR_USA',
    translation: 'usa',
    img: 'dd11.png',
    sliderImg: 'DDR_USA.jpg',
    alt: 'usa',
    type: 'USA',
    btnTxt: 'ourHouseInUS',
  },
  'dubai': {
    name: 'dubai',
    key: 'DDR_DUBAI',
    translation: 'dubai',
    img: 'dd12.png',
    sliderImg: 'DDR_Dubai.jpg',
    alt: 'dubai',
    type: 'DUBAI',
    btnTxt: 'ourHouseInDubai',
  },
  'spain': {
    key: 'DDR_ESPAGNE',
    name: 'spain',
    translation: 'spain',
    img: 'HP_DDR_Preview.png',
    sliderImg: 'DDR_Esp.jpg',
    alt: 'spain',
    type: 'Méditerranée',
    btnTxt: 'ourHouseInSpain',
  },
  'le-nouveau-portugal': {
    name: 'portugal',
    key: 'DDR_PORTUGAL',
    translation: 'portugal',
    img: 'dd8.png',
    sliderImg: 'DDR_Port.jpg',
    alt: 'portugal',
    type: 'Portugal',
    btnTxt: 'ourHouseInPortugal',
  },
  'london-calling': {
    name: 'london',
    key: 'DDR_LONDON',
    translation: 'london',
    img: 'dd9.png',
    sliderImg: 'DDR_Lond.jpg',
    alt: 'london',
    type: 'London',
    btnTxt: 'ourHouseInLondon',
  },
  'bali': {
    key: 'DDR_BALI',
    name: 'bali',
    translation: 'bali',
    img: 'HP_DDR_Preview.png',
    sliderImg: 'DDR_Bali.jpg',
    alt: 'bali',
    type: 'Bali',
    btnTxt: 'ourHouseInBali',
  },
  'greece': {
    key: 'DDR_GRECE',
    name: 'greece',
    translation: 'greece',
    img: 'HP_DDR_Preview.png',
    sliderImg: 'DDR_Grece.jpg',
    alt: 'greece',
    type: 'Grèce',
    btnTxt: 'ourHouseInGrece',
  },
  'normandie-cherie': {
    name: 'normandie-cherie',
    key: 'DDR_NORMANDIE',
    translation: 'normandie-cherie',
    img: 'dd2.png',
    sliderImg: 'DDR_Norm.jpg',
    alt: 'normandie-cherie',
    type: 'Normandie-cherie',
    btnTxt: 'ourHouseInNormandie',
  },
  'loccitanie-terre-de-soleil': {
    name: 'loccitanie-terre-de-soleil',
    key: 'DDR_OCCITANIE',
    translation: 'loccitanie-terre-de-soleil',
    img: 'dd3.png',
    sliderImg: 'DDR_Occ.jpg',
    alt: 'loccitanie-terre-de-soleil',
    type: 'Loccitanie-terre-de-soleil',
    btnTxt: 'ourHouseInOccitanie',
  },
  'la-cote-atlantique': {
    name: 'la-côte-atlantique',
    key: 'DDR_ATLANTIQUE',
    translation: 'la-côte-atlantique',
    img: 'dd4.png',
    sliderImg: 'DDR_Atl.jpg',
    alt: 'la-côte-atlantique',
    type: 'La-côte-atlantique',
    btnTxt: 'ourHouseInOuestCoast',
  },
  'mediterranee-mon-amour': {
    key: 'DDR_MEDITERRANEE',
    name: 'méditerranée-mon-amour',
    translation: 'méditerranée-mon-amour',
    img: 'dd5.png',
    sliderImg: 'DDR_Med.jpg',
    alt: 'méditerranée-mon-amour',
    type: 'Méditerranée',
    btnTxt: 'ourHouseInMediterranee',
  },
  'a-la-montagne': {
    name: 'à-la-montagne',
    key: 'DDR_MONTAGNE',
    translation: 'à-la-montagne',
    img: 'dd6.png',
    sliderImg: 'DDR_Mont.jpg',
    alt: 'à-la-montagne',
    type: 'Montagne',
    btnTxt: 'ourHouseInMontain',
  },
  'beaute-des-iles': {
    name: 'beautès-des-îles',
    key: 'DDR_ILES',
    translation: 'beautès-des-îles',
    img: 'dd7.png',
    sliderImg: 'DDR_Iles.png',
    alt: 'beautès-des-îles',
    type: 'Des-îles',
    btnTxt: 'ourHouseInIsle',
  },
}

type lifestyleType = {
  titleTranslation: string
  leftImg: string
  rightImg: string
  mainImg: string
}

export const LIFESTYLE: {
  [key: string]: lifestyleType
} = {
  'domain-and-castel': {
    titleTranslation: 'lifestyle.domainAndCastel',
    leftImg: 'domains_casteles_left.png',
    rightImg: 'domains_casteles_right.png',
    mainImg: 'domains_casteles_main.png',
  },
  'dream-villa': {
    titleTranslation: 'lifestyle.dreamVilla',
    leftImg: 'dream_villa_left.png',
    rightImg: 'dream_villa_right.png',
    mainImg: 'dream_villa_main.png',
  },
  'family-chalets': {
    titleTranslation: 'lifestyle.familyChalets',
    leftImg: 'family_chalets_left.png',
    rightImg: 'family_chalets_right.png',
    mainImg: 'family_chalets_main.png',
  },
  'typical-space': {
    titleTranslation: 'lifestyle.typicalSpace',
    leftImg: 'lofts_typical_space_left.png',
    rightImg: 'lofts_typical_space_right.png',
    mainImg: 'lofts_typical_space_main.png',
  },
  'garden-terrace': {
    titleTranslation: 'lifestyle.gardenTerrace',
    leftImg: 'terrasses3.png',
    rightImg: 'terrasses2.png',
    mainImg: 'terrasses1.png',
  },
  'private-hotel': {
    titleTranslation: 'lifestyle.privateHotel',
    leftImg: 'private_hotel_left.png',
    rightImg: 'private_hotel_right.png',
    mainImg: 'private_hotel_main.png',
  },
  'haussmannian-apartment': {
    titleTranslation: 'lifestyle.haussmannianApartment',
    leftImg: 'haussmannian_left.png',
    rightImg: 'haussmannian_right.png',
    mainImg: 'haussmannian_main.png',
  },
}

type alertGarden = {
  value: string
  label: string
}

export const ALERT_GARDEN:Array<alertGarden> = [
  {value: 'true', label: 'gardenNeeded'}, 
  {value: 'false', label: 'gardenNotNeeded'}
]

type numberOfBedrooms = {
  value: string
  label: string
}

export const NUMBER_OF_BEDROOMS:Array<numberOfBedrooms> = [
  {value: '1+', label: '1'}, 
  {value: '2+', label: '2'}, 
  {value: '3+', label: '3'}, 
  {value: '4+', label: '4'}, 
  {value: '5+', label: '5'}, 
  {value: '6+', label: '6'}
]

type propertyType = {
  value: string
  label: string
}

export const PROPERTY_TYPE:Array<propertyType> = [
  {
    value: 'A_',
    label: 'A_',
  },
  {
    value: 'APP',
    label: 'APP',
  },
  {
    value: 'IMM',
    label: 'IMM',
  },
  {
    value: 'HOT',
    label: 'HOT',
  },
  {
    value: 'LOF',
    label: 'LOF',
  },
  {
    value: 'MAI',
    label: 'MAI',
  },
  {
    value: 'CHA',
    label: 'CHA',
  },
  {
    value: 'CHL',
    label: 'CHL',
  },
  {
    value: 'VIL',
    label: 'VIL',
  },
  {
    value: 'PAR',
    label: 'PAR',
  },
  {
    value: 'BUR',
    label: 'BUR',
  }
]

export const gmapsStyle = ([
  {
    "featureType": "administrative",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#444444"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "all",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.attraction",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "poi.medical",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "hue": "#007cff"
      }
    ]
  },
  {
    "featureType": "poi.medical",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "hue": "#00b6ff"
      }
    ]
  },
  {
    "featureType": "poi.medical",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "hue": "#0033ff"
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi.medical",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "hue": "#007cff"
      },
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "poi.medical",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#d7e8d6"
      },
      {
        "weight": "4.62"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.school",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "all",
    "stylers": [
      {
        "saturation": -100
      },
      {
        "lightness": 45
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#dcdcdc"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#ebebeb"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "transit.station.airport",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.station.airport",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "transit.station.bus",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.station.rail",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.station.rail",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "all",
    "stylers": [
      {
        "color": "#90d7e7"
      },
      {
        "visibility": "on"
      }
    ]
  }
]);
