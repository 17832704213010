import React from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'
import { useIntl } from 'gatsby-plugin-intl'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import { generatePdf } from '../../utils/submitForm'
import { mailtoLinkShareWithFriend } from '../ModalShare'
import { Launch } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      padding: theme.spacing(2, 2, 0),
    },
    appBar: {
      top: 'auto',
      bottom: 0,
      backgroundColor: '#FFF',
      display: 'none',
      [theme.breakpoints.between('xs', 'sm')]: {
        display: 'block',
      },
    },
    firstBtn: {
      borderRadius: 0,
    },
    fullWidth: {
      width: '100%',
    },
    noPadding: {
      padding: 0,
    },
    rightBorder: {
      borderRight: '1px solid black',
    },
    f10: {
      fontSize: theme.typography.pxToRem(10),
    },
    link: {
      textDecoration: 'none',
      color: 'black'
    },
  }),
)

export default function PropertyBottomBar(props: any) {
  const classes = useStyles()
  const intl = useIntl()
  const { pk, reference, title, isOffmarket } = props

  return (
    <AppBar position="fixed" color="primary" className={clsx(classes.appBar, classes.noPadding)}>
      <Toolbar className={clsx(classes.noPadding)}>
        <Grid container item xs={6}>
          <Button 
            className={clsx(classes.fullWidth, classes.f10)}
            size="small"
            color="primary"
            startIcon={<Launch />}
            onClick={() => 'done'}
          >
            <a 
              href={mailtoLinkShareWithFriend(reference, title, intl)} 
              target={'_blank'}
              className={clsx(classes.link)}
            >
              {intl.formatMessage({ id: 'sendToFriend' })}
            </a>      
          </Button>
        </Grid>
        <Grid container item xs={6}>
          <Button
            className={clsx(classes.fullWidth, classes.f10)}
            startIcon={<PictureAsPdfIcon />}
            onClick={() => generatePdf(pk, reference)}
          >
            {intl.formatMessage({ id: 'downloadTheFile' })}
          </Button>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
