import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import clsx from 'clsx'
import { capitalize } from '../utils/helper'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(26),
    fontWeight: 600,
    lineHeight: 1.2,
    marginTop: theme.typography.pxToRem(18),
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: theme.typography.pxToRem(16),
      fontSize: theme.typography.pxToRem(28),
      fontWeight: 500,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.typography.pxToRem(12),
      fontSize: theme.typography.pxToRem(30),
      fontWeight: 500,
    },
  },
  designation: {
    fontSize: '18px',
    fontFamily: `'Gotham Book', serif`,
  },
  details: {
    textAlign: 'center',
  },
  container: {
    textAlign: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    '.gatsby-image-wrapper': {
      width: '150px',
    },
  },
  pb3: {
    paddingBottom: theme.spacing(3),
  },
  active: {
    borderBottom: '4px solid black',
  },
  num: {
    fontSize: '14px',
    fontFamily: `'Gotham Book', serif`,
  },
}))

const AvatarContainer = styled.div`
  cursor: pointer;
  .gatsby-image-wrapper {
    width: 150px;
  }
`

interface BannerProps {
  onClick?: () => void
  active?: boolean
  hideNumber?: boolean
  hideDesignation?: boolean
  agent: {
    photo: {
      portraitSquare: string
    }
    phone: string
    name: string
    email: string
  }
}

export const Avatar: React.FC<BannerProps> = (props) => {
  const intl = useIntl()
  const { onClick, active = false, hideNumber = false, hideDesignation = false, agent } = props
  const numLink = agent.phone ? 'tel:' + agent.phone?.split(' ').join('') : '#'
  const classes = useStyles()
  return (
    <AvatarContainer
      onClick={() => onClick && onClick()}
      className={clsx(active && classes.active)}
    >
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          {!!agent?.photo && (
            <img
              src={agent.photo.portraitSquare}
              alt="Profile"
              style={{
                borderRadius: '50%',
                width: '150px',
                height: '150px',
                overflow: 'hidden',
              }}
              loading="lazy"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.title}>{agent.name}</Box>
        </Grid>
        <Grid item xs={12}>
          {hideDesignation ? null : (
            <Box className={classes.designation} style={{ fontFamily: 'Cormorant Garamond' }}>
              {agent[`titre${capitalize(intl.locale)}`]}
            </Box>
          )}
        </Grid>
        {hideNumber ? null : (
          <Grid item xs={12}>
            <Link underline="none" href={numLink}>
              {agent.phone}
            </Link>
          </Grid>
        )}
      </Grid>
    </AvatarContainer>
  )
}
