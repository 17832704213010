import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import { useIntl } from 'gatsby-plugin-intl'
import { GoogleMap, LoadScript, Circle } from '@react-google-maps/api'
import MapOutlinedIcon from '@material-ui/icons/MapOutlined'
import { gmapsStyle } from '../../utils/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '50vh',
    padding: theme.spacing(5),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: theme.spacing(2),
    },
  },
  heading: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontFamily: `'Gotham Bold', serif`,
    fontSize: theme.typography.pxToRem(20),
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(10),
  },
  container: {
    flexBasis: '80%',
    [theme.breakpoints.between('xs', 'sm')]: {
      flexBasis: '90%',
    },
    [theme.breakpoints.between('md', 'md')]: {
      flexBasis: '80%',
    },
  },
  extraTitle: {
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  mapDiv: {
    width: '100%',
    height: theme.spacing(70),
  },
}))

interface PropertyMapProps {
  lat: number
  lng: number
  isSmDown: boolean
}

const containerStyle = {
  width: '100%',
  height: '35rem',
}

export const PropertyMap: React.FC<PropertyMapProps> = (props) => {
  const classes = useStyles()
  const { lat, lng, isSmDown } = props
  const intl = useIntl()

  const center = {
    lat: Number.parseFloat(lat.toFixed(3)),
    lng: Number.parseFloat(lng.toFixed(3)),
  }

  const options = {
    strokeColor: '#F10520',
    strokeOpacity: 0.5,
    strokeWeight: 1.2,
    fillColor: '#F10530',
    fillOpacity: 0.20,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1,
  }  

  return (
    <Grid className={classes.root} container justifyContent="center">
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.heading} container justifyContent="center">
          <MapOutlinedIcon style={{ marginRight: '1em', fontSize: '1.3rem', marginTop: '3px' }} />
          {intl.formatMessage({ id: 'locateOnMap' })}
        </Grid>
        <Grid item xs={12} className={classes.mapDiv}>
          <LoadScript googleMapsApiKey="AIzaSyAbHDxCpLwdkI0JZWYcV14-zVm6_dFAR5o">
            <GoogleMap 
              mapContainerStyle={containerStyle} 
              center={center} 
              zoom={12} 
              options={{ 
                disableDefaultUI: true,
                styles: gmapsStyle,
              }}
            >
              <Circle center={center} radius={2100} options={options}></Circle>
            </GoogleMap>
          </LoadScript>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PropertyMap
