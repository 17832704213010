import React from 'react'
import { AvatarContact } from '../AvatarContact'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = (bgClr: string) =>
  makeStyles((theme) => ({
    avatarContainer: {
      backgroundColor: bgClr,
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(10),
    },
    heading: {
      textTransform: 'uppercase',
      fontFamily: 'Gotham Bold',
      fontSize: theme.typography.pxToRem(25),
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(16),
      },

      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(6),
    },
  }))

type AgentType = {
  photo: {
    portraitSquare: string
  }
  phone: string
  name: string
  email: string
}
interface AgentProps {
  bgClr?: string
  showHeading?: boolean
  agent: AgentType
  scrollRef: any
}

const AgentContact: React.FC<AgentProps> = (props) => {
  const { agent, scrollRef } = props

  return <AvatarContact hideDesignation agent={agent} scrollRef={scrollRef} />
}

export default AgentContact
