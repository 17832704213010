import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Carousel from 'react-multi-carousel'
import { Image } from '../Images'

const useStyles = makeStyles(() => ({
  imgBox: {
    paddingRight: '1rem',
  },
}))

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const PropertySlider = () => {
  const classes = useStyles()

  return (
    <Carousel
      responsive={responsive}
      additionalTransfrom={0}
      arrows
      autoPlaySpeed={5000}
      centerMode
      focusOnSelect={false}
      infinite
      keyBoardControl
      minimumTouchDrag={80}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
    >
      <Box className={classes.imgBox}>
        <Image src="display1.png" alt="disp1" layout="CONSTRAINED" />
      </Box>
      <Box className={classes.imgBox}>
        <Image src="display2.png" alt="disp2" layout="CONSTRAINED" />
      </Box>
      <Box className={classes.imgBox}>
        <Image src="display3.png" alt="disp3" layout="CONSTRAINED" />
      </Box>
    </Carousel>
  )
}

export default PropertySlider
