import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import clsx from 'clsx'
import Link from '@material-ui/core/Link'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import PhoneIcon from '@material-ui/icons/Phone'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import Hidden from '@material-ui/core/Hidden'
import { Button } from '@material-ui/core'
import { useLocation } from '@reach/router'

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(26),
    fontWeight: 600,
    lineHeight: 1.2,
    marginTop: theme.typography.pxToRem(18),
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: theme.typography.pxToRem(16),
      fontSize: theme.typography.pxToRem(28),
      fontWeight: 500,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.typography.pxToRem(12),
      fontSize: theme.typography.pxToRem(30),
      fontWeight: 500,
    },
  },
  designation: {
    fontSize: '18px',
    fontFamily: `'Gotham Book', serif`,
  },
  details: {
    textAlign: 'center',
  },

  font: {
    fontSize: theme.typography.pxToRem(12),
    marginRight: theme.spacing(1),
    marginTop: '2%',
    marginLeft: theme.spacing(2),
  },
  pb3: {
    paddingBottom: theme.spacing(3),
  },
  active: {
    borderBottom: '4px solid black',
  },
  num: {
    fontSize: '14px',
    fontFamily: `'Gotham Book', serif`,
  },
  phoneFont: {
  
    fontSize: '1rem',
  },
  mailFont: {
    fontSize: '1rem',
  },
  whatsappFont: {
    textTransform: 'none',
    fontSize: '1rem',
  }
}))

const AvatarContainer = styled.div`
  cursor: pointer;
  .gatsby-image-wrapper {
    width: 150px;
  }
`

interface BannerProps {
  onClick?: () => void
  active?: boolean
  hideNumber?: boolean
  hideDesignation?: boolean
  scrollRef: any
  agent: {
    photo: {
      portraitSquare: string
    }
    phone: string
    name: string
    email: string
  }
}

export const AvatarContact: React.FC<BannerProps> = (props) => {
  const intl = useIntl()
  const {
    onClick,
    active = false,
    hideNumber = false,
    hideDesignation = false,
    agent,
    scrollRef,
  } = props
  const numLink = agent?.phone ? 'tel:' + agent?.phone?.split(' ').join('') : '#'
  const classes = useStyles()
  const location = useLocation()

  const scrollBottom = (e: {
    current: { scrollIntoView: (arg0: { behavior: string }) => void }
  }) => {
    e.current.scrollIntoView({
      behavior: 'smooth',
    })
  }
  const phoneAgentWP = agent?.phone ? 'https://wa.me/' + agent?.phone?.split(' ').join('') + '?text=' + intl.formatMessage({ id: 'whatsappMsgFirstPart' }) + location.href + intl.formatMessage({ id: 'whatsappMsgSecondPart' }) : '#'

  return (
    <AvatarContainer
      onClick={() => onClick && onClick()}
      className={clsx(active && classes.active)}
    >
      <Grid
        style={{
          display: 'flex',

          marginTop: '3rem',
        }}
      >
        <Grid>
          {!!agent?.photo && (
            <img
              src={agent.photo.portraitSquare}
              alt="Profile"
              style={{
                borderRadius: '50%',
                width: '150px',
                height: '150px',
                overflow: 'hidden',
              }}
              loading="lazy"
            />
          )}
        </Grid>

        <Grid style={{ paddingTop: '3px',marginLeft:'12px' }}>
          <Grid style={{ width: 'max-content' }} item className={classes.title}>
            {agent?.name}
          </Grid>

          <Grid
            item
            xs={12}
            style={{ width: 'max-content', marginTop: '3px' }}
            alignItems="flex-start"
            
          >
            {hideNumber ? null : (
              <Grid item xs={12} direction='row' container >
                <Grid style={{ paddingTop: '2px',marginRight:'4px' }} >
                <PhoneIcon className={classes.phoneFont} />
                </Grid>
                <Link underline="none" href={numLink}>
                  {agent?.phone}
                </Link>
              </Grid>
            )}

            <Grid item xs={12} direction='row' container>
              <Grid style={{ paddingTop: '3px',marginRight:'4px' }}>
              <MailOutlineIcon className={classes.mailFont} />
              </Grid>
              <Link underline="none" onClick={() => scrollBottom(scrollRef)}>
                {intl.formatMessage({ id: 'contactByEmail' })}
              </Link>
            </Grid>
            <Hidden smUp>
              <Grid xs={12} item direction='row' container>
                <Grid style={{ paddingTop: '2px',marginRight:'4px' }} >
                  <WhatsAppIcon className={classes.whatsappFont} />
                </Grid>
                <Link underline="none" href={phoneAgentWP}>
                  {intl.formatMessage({id: 'whatsappCall'})}
                </Link>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    </AvatarContainer>
  )
}
