import styled from 'styled-components'

interface Props {
  opacity?: number;
  height?: string;
  side?: string;
  color?: string;
  fontSize?: string;
}

const CustomArrow = styled.button`
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 100;
  border: 0;
  background: transparent;
  cursor: pointer;
  min-width: 43px;
  min-height: 43px;
  opacity: ${(props: Props) => props.opacity ? props.opacity : 1};
  width: 25%;
  height: ${(props: Props) => props.height ? props.height : '60%'};
  top: 0;
  ${(props: Props) => (props.side === 'left' ? ' left: 0' : 'right: 0')}};
  right: 0;
  &:before {
    position: absolute;
    content: ' ';
    width: 1em;
    height: 1em;
    border-left: ${(props: Props) => props.color ? `0.1em solid ${props.color}` : '0.1em solid white'};
    border-bottom: ${(props: Props) => props.color ? `0.1em solid ${props.color}` : '0.1em solid white'};
    display: block;
    transform: rotate(${(props: Props) => (props.side === 'left' ? 45 : 220)}deg);
    ${(props: Props) => (props.side === 'left' ? ' left: 1.5rem' : 'right: 1.5rem')}};
    font-size: ${(props: Props) => props.fontSize ? props.fontSize : '1.3rem'};
  }
`

export default CustomArrow
