import React from 'react'
import Grid from '@material-ui/core/Grid'
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'gatsby-plugin-intl'
import VideoPlayer from '../VideoPlayer'
import RwdIframeWrapper from '../RwdIframeWrapper'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.info.main,
    padding: theme.spacing(5),
  },
  heading: {
    textTransform: 'uppercase',
    fontFamily: `'Gotham Bold', serif`,
    fontSize: theme.typography.pxToRem(30),
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(10),
    alignItems: 'center',
    [theme.breakpoints.between('sm', 'md')]: {
      marginBottom: theme.spacing(5),
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
      marginTop: 0,
      fontSize: theme.typography.pxToRem(16),
    },
  },
  container: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  icon: {
    marginRight: theme.typography.pxToRem(8),
    fontSize: theme.typography.pxToRem(30),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(16),
      marginRight: theme.typography.pxToRem(4),
    },
  },
}))

interface PropertyDisplayProps {
  filmedSrc?: string
  visitSrc?: string
}

export const PropertyDisplay: React.FC<PropertyDisplayProps> = (props) => {
  const classes = useStyles()
  const { filmedSrc, visitSrc } = props
  const intl = useIntl()

  return (
    <Grid className={classes.root} container justifyContent="center">
      <Grid item xs={8} className={classes.container}>
        {filmedSrc && (
          <>
            <Grid item xs={12} className={classes.heading} container justifyContent="center">
              <CameraAltOutlinedIcon className={classes.icon} />
              {intl.formatMessage({ id: 'filmedVisit' })}
            </Grid>
            <Grid item xs={12}>
              <VideoPlayer urls={filmedSrc} />
            </Grid>
          </>
        )}
        {visitSrc && (
          <>
            <Grid item xs={12} className={classes.heading} container justifyContent="center">
              <CameraAltOutlinedIcon className={classes.icon} />
              {intl.formatMessage({ id: 'visit3d' })}
            </Grid>
            <Grid item xs={12}>
              <RwdIframeWrapper>
                <iframe
                  src={visitSrc}
                  title="vistire virtuelle"
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                  }}
                />
              </RwdIframeWrapper>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default PropertyDisplay
