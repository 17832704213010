import React from 'react'
import imgSrcSquare from '../images/bg-transparent-square.png'

const RwdIframeWrapper = ({ children }) => {
  return (
    <div style={{ width: '100%',  maxHeight: '75vh', height: '100%', position: 'relative' }}>
      {children}
      <img
        src={imgSrcSquare}
        alt="placeholder"
        style={{ width: '100%', maxHeight: '75vh', height: '100%', display: 'block' }}
        loading="lazy"
      />
    </div>
  )
}

export default RwdIframeWrapper
