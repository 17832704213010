import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Carousel from 'react-multi-carousel'
import { Grid } from '@material-ui/core'
import PropertyDialog from './PropertyDialog'

const useStyles = makeStyles((theme) => ({
  imgBox: {
    paddingRight: '1rem',
    cursor: 'pointer',
  },
  carouselImg: {
    height: '100vh',
    maxWidth: '100vw',
    margin: 'auto',
    objectFit: 'contain',
    display: 'block',
  },
}))

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const PropertyGallery = ({ srcs }) => {
  const classes = useStyles()
  const [imgChoosen, setImgChoosen] = useState('')

  return (
    <Grid container>
      <Grid item xs={12}>
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={5000}
          centerMode
          focusOnSelect={false}
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
        >
          {srcs.map(({ src }) => (
            <Box className={classes.imgBox} key={src}>
              <img
                src={src}
                alt="disp1"
                style={{ width: '100%' }}
                onClick={() => setImgChoosen(src)}
                loading="lazy"
              />
            </Box>
          ))}
        </Carousel>
      </Grid>
      <PropertyDialog isOpen={!!imgChoosen} close={() => setImgChoosen(null)}>
        <img src={imgChoosen} alt="disp1" className={classes.carouselImg} loading="lazy"/>
      </PropertyDialog>
    </Grid>
  )
}

export default PropertyGallery
