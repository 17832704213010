import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'gatsby-plugin-intl'
import Estimate from './Estimate'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.info.main,
    padding: theme.spacing(5),
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: theme.spacing(2),
    },
  },
  heading: {
    textTransform: 'uppercase',
    // fontWeight: 600,
    fontFamily: `'Gotham Bold', serif`,
    fontSize: theme.typography.pxToRem(22),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(15),
    },
  },
  subheading: {
    fontSize: theme.typography.pxToRem(24),
    fontFamily: `'Cormorant Garamond', serif`,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  container: {
    flexBasis: '60%',
    [theme.breakpoints.between('xs', 'sm')]: {
      flexBasis: '90%',
    },
    [theme.breakpoints.between('md', 'md')]: {
      flexBasis: '80%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: theme.typography.pxToRem(200),
      textAlign: 'center',
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
  },
  extraTitle: {
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  okBtn: {
    width: 50,
    height: 50,
    fontSize: theme.typography.pxToRem(16),
  },
}))

export const PropertyFooter: React.FC = () => {
  const classes = useStyles()
  const intl = useIntl()

  const [estimateFormVisible, setEstimateFormVisible] = useState<boolean>(false)

  return (
    <Grid className={classes.root} container justifyContent="center">
      <Grid className={classes.container}>
        <Grid item xs={12} className={classes.heading} container justifyContent="center">
          {intl.formatMessage({ id: 'sellToBuy' })} ?
        </Grid>
        <Grid item xs={12} className={classes.subheading} container justifyContent="center">
          {intl.formatMessage({ id: 'freeEstimate' })}
        </Grid>
        {!estimateFormVisible && (
          <Grid item xs={12} container justifyContent="center">
            <Button
              className={classes.okBtn}
              style={{ border: 'none', textDecoration: 'underline' }}
              variant="outlined"
              onClick={() => setEstimateFormVisible(true)}
            >
              Ok
            </Button>
          </Grid>
        )}
      </Grid>
      {estimateFormVisible && <Estimate defaultOpen={true} />}
    </Grid>
  )
}

export default PropertyFooter
