import { Checkbox, Grid, TextareaAutosize, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback, useState } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import clsx from 'clsx'
import { CustomButton } from '../../CustomButton'
import { submitForm } from '../../../utils/submitForm'
import Link from '@material-ui/core/Link'
import styled from 'styled-components'
import theme from '../../../Theme/theme'

const useStyles = makeStyles((theme) => ({
  contactContainer: {
    backgroundColor: theme.palette.info.main,
    padding: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: theme.spacing(2),
    },
  },
  heading: {
    textTransform: 'uppercase',
    fontFamily: `'Gotham bold', serif`,
    fontSize: theme.typography.pxToRem(25),
    fontWeight: 600,
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(15),
    },
  },
  subHeading: {
    textTransform: 'uppercase',
    fontFamily: 'Gotham Medium',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    marginTop: theme.spacing(6),
  },
  container: {
    flexBasis: '50%',
    [theme.breakpoints.between('xs', 'sm')]: {
      flexBasis: '90%',
    },
    [theme.breakpoints.between('md', 'md')]: {
      flexBasis: '80%',
    },
  },
  font: {
    fontSize: theme.typography.pxToRem(12),
    marginRight: theme.spacing(1),
  },
  w100: {
    width: '100%',
  },
  w170: {
    width: '100%',
  },
  transparent: {
    backgroundColor: 'transparent',
    borderBottom: 'none',
    borderRight: 'none',
  },
  mb2: {
    marginBottom: '10px',
  },
  mt5: {
    marginTop: theme.spacing(3),
  },
  pr3: {
    paddingRight: theme.spacing(3),
  },
  pb2: {
    paddingBottom: '10px',
  },
  pl3: {
    paddingLeft: theme.spacing(3),
  },
  blackColor: {
    color: '#000',
    fontFamily: `'Gotham Book', serif`,
    fontSize: '.9rem',
  },
  consent: {
    fontSize: '.8em',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
  },
}))

const Square = styled.div`
  width: 12px;
  height: 12px;
  border: 1px solid #606060;
  margin: 0px;
  background: transparent;
`
const FullSquare = styled.div`
  width: 12px;
  height: 12px;
  border: 1px solid #606060;
  margin: 0px;
  background: #979797;
`
const SquareRed = styled.div`
  width: 12px;
  height: 12px;
  border: 1px solid #FF0000;
  margin: 0px;
  background: transparent;
`

const reEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const MailForm = ({ 
  onSuccess=null,
  initialValues={
    reference: null,
    emailTo: null,
    email: null,
    name: null,
    message: null,
    form: 'FCO',
    consent: false,
  } as any
}) => {
  const classes = useStyles()
  const intl = useIntl()

  const [formValues, setFormValues] = useState(initialValues)

  const [error, setError] = useState(['initial'])
  const [submited, setSubmited] = useState(false)

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown; textContent?: any; checked?: boolean }>) => {
      if (event && event.target && (event.target.name || event.target.textContent)) {
        setError([])
        setFormValues({ ...formValues, [event.target.name || 'adresse' as string]: (event.target.name == 'consent' ? event.target.checked : event.target.value) || event.target.textContent })
      }
    },
    [formValues],
  )

  const onSubmit = async () => {
    const validateField = Object.keys(formValues).filter(
      (key: string) => 
      !formValues[key] || 
      (key == 'email' && !String(formValues[key]).match(reEmail)) ||
      (key == 'emailTo' && !String(formValues[key]).match(reEmail))      
    ) as any
    if (validateField.length) {
      setError(validateField)
    } else {
      const result = await submitForm(formValues)
      if (result) {
        setError([])
        setSubmited(true)
      }
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <TextField
          id="name"
          name="name"
          label={` ${intl.formatMessage({ id: 'yourName' })}`}
          error={error.includes('name')}
          className={clsx(classes.w100, classes.mb2, classes.blackColor)}
          onChange={handleChange}
          InputLabelProps={{ className: classes.blackColor }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="email"
          name="email"
          type="email"
          error={error.includes('email')}
          label={` ${intl.formatMessage({ id: 'yourEmail' })}`}
          onChange={handleChange}
          InputLabelProps={{ className: classes.blackColor }}
          className={clsx(classes.w100, classes.mb2, classes.blackColor)}
        />
      </Grid>
      <Grid xs={12} item className={clsx(classes.mt5, classes.mb2)}>
        <div className={clsx(classes.w100, classes.mb2, classes.blackColor)}>
          {intl.formatMessage({ id: 'yourMessage' }) + '...'}
        </div>
      </Grid>
      <Grid xs={12} item>
        <TextareaAutosize
          onChange={handleChange}
          name="message"
          className={clsx(classes.w170, classes.transparent, classes.mb2)}
          aria-label={intl.formatMessage({ id: 'yourMessage' })}
          minRows={8}
        />
      </Grid>
      <Grid xs={12} item className={classes.consent} justifyContent="center">
        <Grid style={{textAlign: 'left', paddingTop: '8px', paddingBottom: '8px'}}>
          <span>
            {intl.formatMessage({ id: 'legalConsent.mailForm.agree' })}
            <Checkbox
              size="small"
              icon={formValues.consent == false && !error.includes('initial') ? <SquareRed /> : <Square />}
              checkedIcon={<FullSquare />}
              checked={formValues.consent}
              onChange={handleChange}
              name={'consent'}
              style={{padding: '0 0 0 4px'}}
            />
          </span>
        </Grid>
        <Grid style={{textAlign: 'left', paddingTop: '12px'}}>
          <span>
            {intl.formatMessage({ id: 'legalConsent.mailForm.moreInformation' })}
            <Link
              href={
                '/' +
                intl.locale +
                '/legal-privacy-policy'
              }
              underline="none"
            >
              {intl.formatMessage({ id: 'clickHere' })}
            </Link>
          </span>
        </Grid>
      </Grid>
      <Grid xs={12} item className={classes.mt5}>
        {submited ? (
          intl.formatMessage({ id: 'send_confirm' })
        ) : (
          <CustomButton textTransform="inherit" darkBoarder height={'40px'} onClick={onSubmit}>
            <span style={{ fontSize: '0.8rem', padding: '0 0px', fontWeight: 400 }}>
              {intl.formatMessage({ id: 'send' })}
            </span>
          </CustomButton>
        )}
      </Grid>
    </>
  )
}

export default MailForm
