import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'gatsby-plugin-intl'
import clsx from 'clsx'
import PhoneIcon from '@material-ui/icons/Phone'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import PhoneForm from './propertyForm/PhoneForm'
import MailForm from './propertyForm/MailForm'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles((theme) => ({
  contactContainer: {
    backgroundColor: theme.palette.info.main,
    padding: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: theme.spacing(2),
    },
  },
  heading: {
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(25),
    fontWeight: 600,
    fontFamily: 'Gotham Medium',
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(15),
    },
  },
  subHeading: {
    textTransform: 'uppercase',
    fontFamily: 'Gotham Medium',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    marginTop: theme.spacing(6),
  },
  container: {
    flexBasis: '50%',
    [theme.breakpoints.between('xs', 'sm')]: {
      flexBasis: '90%',
    },
    [theme.breakpoints.between('md', 'md')]: {
      flexBasis: '80%',
    },
  },
  font: {
    fontSize: theme.typography.pxToRem(12),
    marginRight: theme.spacing(1),
  },
  w100: {
    width: '100%',
  },
  w170: {
    width: '130%',
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100%',
    },
  },
  transparent: {
    backgroundColor: 'transparent',
    borderBottom: 'none',
    borderRight: 'none',
  },
  mb2: {
    marginBottom: '10px',
  },
  mb3: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(5),
    },
  },
  mt5: {
    marginTop: theme.spacing(4),
  },
  pr3: {
    paddingRight: theme.spacing(3),
  },
  pb2: {
    paddingBottom: '10px',
  },
  pl3: {
    paddingLeft: theme.spacing(3),
  },
  blackColor: {
    color: '#000',
  },
}))

const AgentContactForm: React.FC<any> = ({
  reference,
  emailTo,
}: {
  reference: string
  emailTo: string
}) => {
  const classes = useStyles()
  const intl = useIntl()
  const emailLink = emailTo ? 'mailto:' + emailTo : '#'

  return (
    <Grid className={classes.contactContainer} container justifyContent="center">
      <Grid item xs={12} className={classes.heading} container justifyContent="center">
        {intl.formatMessage({ id: 'contactAgent' })}
      </Grid>
      <Grid className={classes.container}>
        <Grid item xs={12} container>
          <Grid xs={12} md={6} item container justifyContent="center">
            <Grid container className={classes.pr3}>
              <Grid xs={12} item className={clsx(classes.subHeading)}>
                <PhoneIcon className={classes.font} />
                <b>{intl.formatMessage({ id: 'callTheAgency' })}</b>
              </Grid>
              <Grid xs={12} item>
                <Link underline="none" href="tel:+33141109122">
                  +33 (0)1 41 10 91 22
                </Link>
              </Grid>
              <PhoneForm reference={reference} />
            </Grid>
          </Grid>
          <Grid md={1} item />
          <Grid xs={12} md={4} item container justifyContent="center" className={classes.mb3}>
            <Grid container className={classes.mb3}>
              <Grid xs={12} item className={clsx(classes.subHeading)}>
                <MailOutlineIcon className={classes.font} />
                <Link underline="none" href={emailLink}>
                  {intl.formatMessage({ id: 'contactByEmail' })}
                </Link>
              </Grid>
              <MailForm 
                initialValues={{
                  reference: reference,
                  emailTo: emailTo as any,
                  email: null,
                  name: null,
                  message: null,
                  form: 'FCO',
                  consent: false,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AgentContactForm
