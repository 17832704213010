import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { getDisplayedPrice, numberWithSpacesAndCurrency } from '../../localization/number-formatter'

const Line = styled.div`
  border-bottom: 0.7px solid gray;
  width: 1.5rem;
  margin: 0 0.5rem;
  height: 0.8em;
`

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFFFFF',
    opacity: 0.9,
    padding: '3rem 3rem 1.5rem 3rem',
    maxWidth: '620px',
    width: 'fit-content',
  },
  head: {
    fontSize: '0.8rem',
    fontFamily: `'Gotham Book', serif`,
    marginBottom: theme.spacing(1),
  },
  title: {
    lineHeight: '1.1',
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(38),
  },
  specific: {
    fontSize: '0.9rem',
    display: 'flex',
    alignItems: 'center',
  },
  detail: {
    fontFamily: `'Gotham Book', serif`,
    fontSize: '0.8rem',
    textAlign: 'left',
    marginBottom: theme.spacing(2),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: ' -webkit-box !important',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    'white-space': 'normal',
  },
  specificDetails: {
    flexWrap: 'nowrap',
    marginTop: '1rem',
    lineHeight: 1,
    fontFamily: `'Gotham Book', serif`,
    alignItems: 'baseline',
  },
  price: {
    fontFamily: `'Cormorant Garamond', serif`,
    marginTop: '1rem',
    width: '100%',
    textAlign: 'right',
    fontSize: theme.typography.pxToRem(34),
    whiteSpace: 'nowrap',
    lineHeight: 1,
  },
  priceSymbol: {
    fontSize: theme.typography.pxToRem(18),
    color: '#272727',
  },
  priceCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  city: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(14),
  },
}))

interface PropertyBoxProps {
  area: string
  confidentialPrice: boolean
  isRented: boolean
  isSeasonal: boolean
  pieces: string
  price: string
  priceMax: string
  reference: string
  size: string
  title: string
  unitArea: string
  unitPrice: string
  ville: string
  villeFr?: string
  villeEn?: string
  villeEs?: string
  villePt?: string
}

const getCityName = (props: PropertyBoxProps, locale: string) => {
  const { ville, villeFr, villeEn, villeEs, villePt } = props
  switch (locale) {
    case 'fr':
      return villeFr || ville
    case 'es':
      return villeEs || ville
    case 'en':
      return villeEn || ville
    case 'pt':
      return villePt || ville
    default:
      return ville
  }
}

export const PropertyBox: React.FC<PropertyBoxProps> = (props) => {
  const {
    title,
    price,
    reference,
    area,
    size,
    pieces,
    confidentialPrice,
    isRented,
    unitPrice,
    isSeasonal,
    priceMax,
    unitArea,
  } = props
  const classes = useStyles()
  const intl = useIntl()

  const ville = getCityName(props, intl.locale)

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid container justifyContent="space-between" className={classes.head}>
        <Grid item className={classes.city}>
          {ville}
        </Grid>
        <Grid item>REF : {reference}</Grid>
      </Grid>
      <Grid container className={classes.title} justifyContent="flex-start">
        {title}
      </Grid>
      <Grid container className={clsx(classes.specific)} justifyContent="flex-start">
        <Grid item className={classes.specificDetails} container>
          <div>{`${area} `}</div>
          <Line />
          <div>{` ${size} `}</div>
          <Line />
          <div>{` ${pieces}`}</div>
        </Grid>
        <div className={clsx(classes.price)}>
          {getDisplayedPrice(
            price,
            priceMax,
            unitPrice,
            confidentialPrice,
            intl.formatMessage({ id: 'confidentialPrice' }),
            isRented,
            isSeasonal,
            intl.formatMessage({ id: 'perMonths' }),
            intl.formatMessage({ id: 'perWeeks' }),
            intl.formatMessage({ id: 'fromshort' }),
            intl.formatMessage({ id: 'toshort' }),
            false,
          )}
        </div>
      </Grid>
    </Grid>
  )
}
