import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { KretzIconCircle } from '../../icons/KretzIconCircle'
import Hidden from '@material-ui/core/Hidden'
import styled from 'styled-components'
import { useIntl } from 'gatsby-plugin-intl'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import { getDisplayedPrice } from '../../localization/number-formatter'
import ModalShare from '../ModalShare'
import { Button } from '@material-ui/core'
import { generatePdf } from '../../utils/submitForm'
import AgentContact from './AgentContact'
import VideoPlayer from '../VideoPlayer'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { useLocation } from '@reach/router';

const Line = styled.div`
  border-bottom: 0.7px solid gray;
  width: 1.5rem;
  margin: 0 0.5rem;
`

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: theme.spacing(70),
    paddingTop: theme.spacing(15),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(5),
      marginBottom: theme.spacing(2),
    },
  },
  head: {
    fontSize: '0.8rem',
    marginBottom: theme.spacing(2),
  },
  title: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(60),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(45),
    },
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(50),
    },
  },
  specific: {
    alignItems: 'baseline',
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(5),

    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(10),
      marginBottom: theme.spacing(2),
    },
  },
  detail: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.7,
    letterSpacing: 0,
    textAlign: 'left',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.between('md', 'md')]: {
      marginTop: theme.spacing(8),
      fontSize: theme.typography.pxToRem(14),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(13),
      marginTop: theme.spacing(4),
    },
  },
  detailContent: {
    paddingRight: '2rem',

    [theme.breakpoints.between('xs', 'sm')]: {
      paddingRight: '0rem',
    },
  },
  price: {
    marginTop: '-10px',
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(30),
    alignSelf: 'center',
  },
  priceCompact: {
    marginTop: '-18px',
    marginLeft: '7rem',
  },
  priceSymbol: {
    fontSize: theme.typography.pxToRem(24),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(15),
    },
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  launch: {
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(12),
    },
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  content: {
    position: 'relative',
  },
  launchDiv: {
    marginBottom: theme.spacing(10),
  },
  detailsDiv: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.between('lg', 'md')]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  circle: {
    height: 'auto',
    width: '10rem',
  },

  playerContainerMobile: {
    position: 'static',
    marginBottom: '-100px',
    display: 'block',
  },
  videoContainer: {
    marginLeft: '70%!important',
    marginTop: '-28%!important',
  },
  refData: {
    marginRight: '14%',
    [theme.breakpoints.between('md', 'md')]: {
      marginRight: '12rem',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginRight: '-20%',
    },
  },
  refContent: {
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: '12%',
    },
  },

  headTxt: {
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  priceSymWrapper: {
    paddingLeft: '5px',
    [theme.breakpoints.between('lg', 'xl')]: {
      paddingTop: '6px',
    },
  },
  button: {
    fontWeight: 400,
    textTransform: 'initial',
  },
  city: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(14),
  },
  bodyContentcontainer: {
    display: 'flex',
  },
  bodyContentcontainerItem: {
    display: 'flex',
  },
}))

interface PropertyDetailsProps {
  area: string
  city: string
  confidentialPrice: boolean
  details?: string
  isOffmarket: boolean
  isRented: boolean
  isSeasonal: boolean
  photo: any
  pieces: string
  pk: string
  price: string
  priceMax: boolean
  reference: string
  size: string
  title: string
  unitPrice: string
  agent: string
  filmedSrc: string
  scrollRef: any
}


export const PropertyDetails: React.FC<PropertyDetailsProps> = (props) => {
  const classes = useStyles()
  const intl = useIntl()
  const {
    title,
    price,
    reference,
    city,
    area,
    size,
    pieces,
    details,
    pk,
    confidentialPrice,
    isRented,
    isOffmarket,
    unitPrice,
    isSeasonal,
    priceMax,
    agent,
    scrollRef,
    filmedSrc,
  } = props

  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))
  const isSm = useMediaQuery(theme.breakpoints.only('sm'))
  const isMd = useMediaQuery(theme.breakpoints.only('md'))
  const isLg = useMediaQuery(theme.breakpoints.only('lg'))
  const isXl = useMediaQuery(theme.breakpoints.only('xl'))

  const getAvatarMargin = () => {
    if (isXs || isSm) {
      return -37
    } else {
      return 0
    }
  }

  return (
    <Grid className={classes.root} container>
      <Grid item xs={2} md={2} container className={classes.content}></Grid>
      <Grid item xs={8} md={10} container className={classes.content}>
        <Grid className={classes.refContent} container>
          <Grid
            item
            xs={12}
            lg={11}
            container
            direction="row"
            justifyContent="space-between"
            className={classes.headTxt}
          >
            <Grid item className={classes.city}>
              {city}
            </Grid>
            <Grid item className={classes.refData}>
              {`REF : ${reference}`}
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            md={8}
            container
            direction="column"
            className={classes.title}
            justifyContent="flex-start"
          >
            <Grid item>{title}</Grid>
          </Grid>
          <Grid
            md={4}
            style={{
              paddingLeft: '4rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Hidden smDown>
              <Grid>
                <KretzIconCircle className={classes.circle} />
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
        <Grid container lg={12}>
          <Grid
            container
            item
            xs={12}
            lg={12}
            className={classes.specific}
            justifyContent="flex-start"
          >
            <div>{`${area} `}</div>
            <div>
              <Line />
            </div>
            <div>{` ${size} `}</div>
            <div>
              <Line />
            </div>
            <div>{` ${pieces}`}</div>
          </Grid>
          <Grid container direction="row" xs={12} md={12} lg={12} justifyContent="flex-start">
            <Grid container direction="row" xs={12} md={10} lg={8}>
              <Grid xs={12} md={3} lg={3} className={classes.price}>
                <div>
                  {getDisplayedPrice(
                    price,
                    priceMax,
                    unitPrice,
                    confidentialPrice,
                    intl.formatMessage({ id: 'confidentialPrice' }),
                    isRented,
                    isSeasonal,
                    intl.formatMessage({ id: 'perMonths' }),
                    intl.formatMessage({ id: 'perWeeks' }),
                    intl.formatMessage({ id: 'fromshort' }),
                    intl.formatMessage({ id: 'toshort' }),
                    false,
                  )}
                </div>
              </Grid>
              <Hidden smDown>
                <Grid
                  style={{
                    marginTop: '-3px',
                  }}
                  xs={12}
                  md={3}
                  lg={3}
                  className={classes.launch}
                >
                  <ModalShare reference={!isOffmarket ? reference : ''} title={title} />
                </Grid>
                <Grid xs={12} md={3} lg={3} className={classes.launch}>
                  <Button
                    className={classes.button}
                    size="small"
                    color="primary"
                    startIcon={<PictureAsPdfIcon className={classes.launch} />}
                    onClick={() => generatePdf(pk, reference)}
                  >
                    {intl.formatMessage({ id: 'downloadTheFile' })}
                  </Button>
                </Grid>
              </Hidden>
            </Grid>

            <Grid item xs={12} md={12} className={classes.detailsDiv}>
              <Grid container className={classes.detail}>
                <Grid
                  style={{
                    marginBottom: '31px',
                  }}
                  item
                  xs={12}
                  md={8}
                  lg={7}
                >
                  <Grid md={10} lg={12} className={classes.detailContent}>
                    {details}
                  </Grid>
                  <Grid item xs={12} style={{ marginLeft: `${getAvatarMargin()}px` }}>
                    <AgentContact agent={agent} scrollRef={scrollRef}></AgentContact>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <VideoPlayer urls={filmedSrc} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PropertyDetails
