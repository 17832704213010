import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Avatar } from '../Avatar'
import { makeStyles } from '@material-ui/core/styles'

import { useIntl } from 'gatsby-plugin-intl'

const useStyles = (bgClr: string) =>
  makeStyles((theme) => ({
    avatarContainer: {
      backgroundColor: bgClr,
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(10),
    },
    heading: {
      textTransform: 'uppercase',
      fontFamily: 'Gotham Bold',
      fontSize: theme.typography.pxToRem(25),
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(16),
      },
      // fontWeight: 600,
      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(6),
    },
  }))

type AgentType = {
  photo: {
    portraitSquare: string
  }
  phone: string
  name: string
  email: string
}
interface AgentProps {
  bgClr?: string
  showHeading?: boolean
  agent: AgentType
}

const Agent: React.FC<AgentProps> = (props) => {
  const { bgClr = '#FAE9E5', showHeading = true, agent } = props
  const classes = useStyles(bgClr)()
  const intl = useIntl()

  return (
    <>
      <Grid className={classes.avatarContainer} container justifyContent="center">
        {showHeading && (
          <Grid xs={12} justifyContent="center" item container className={classes.heading}>
            {intl.formatMessage({ id: 'intrestedInProperty' })}
          </Grid>
        )}
        <Grid item xs={12}>
          <Avatar hideDesignation agent={agent} />
        </Grid>
      </Grid>
    </>
  )
}

export default Agent
