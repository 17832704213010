import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import axios from 'axios'
import './Player.css'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

interface VideoPlayerProps {
  urls: string
  controls?: boolean
}

const VideoPlayer: React.FC<VideoPlayerProps> = (props) => {
  const { urls, controls = true, ...rest } = props
  if (!urls) return <></>

  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))
  const isSm = useMediaQuery(theme.breakpoints.only('sm'))
  const isMd = useMediaQuery(theme.breakpoints.only('md'))
  const isLg = useMediaQuery(theme.breakpoints.only('lg'))
  const isXl = useMediaQuery(theme.breakpoints.only('xl'))

  const getVideoWidth = () => {
    if ((isXs || isSm) && videoProps.format == 'horizontal') {
      return 110
    } else if (isMd && videoProps.format == 'horizontal') {
      return 90
    }
    else if (isLg  && videoProps.format == 'horizontal'){  
      return 100
    } 
    else if (isXl && videoProps.format == 'horizontal'){
      return 100
    }    
    else if ((isXs || isSm) && videoProps.format == 'vertical') {
      return 110
    }
    else if (isXl && videoProps.format == 'vertical') {
      return 120
    }   
    else {
      return 100
    }
  }

  const getVideoMargin = () => {
    if ((isXs || isSm) && videoProps.format == 'vertical') {
      return -16
    } 
   else if ((isXs || isSm) && videoProps.format == 'horizontal') {  
      return -30
    } 
    else if (isLg  && videoProps.format == 'horizontal'){
      return 26 
    }   
    else if (isMd && videoProps.format == 'horizontal') {   
      return -100
    }  
   else if (isMd && videoProps.format == 'vertical') {
      return -128
    }
    else {
      return 0
    } 
  }

  const [videoProps, setData] = useState({ height: 16, width: 9, format: 'vertical' })
  useEffect(() => {
    let isMounted = true
    axios
      .get(`https://vimeo.com/api/oembed.json?url=${urls}`, { params: { format: 'json' } })
      .then((response) => {
        if (isMounted) {
          setData({
            ...response.data,
            format: response.data.width / response.data.height > 1 ? 'horizontal' : 'vertical',
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
    return () => {
      isMounted = false
    }
  }, [urls])

  return (
    <div
      style={{ marginLeft: `${getVideoMargin()}px` }}
      className={`player-wrapper-${videoProps.format}`}
    >
      <ReactPlayer
        url={urls}
        controls={controls}
        width={`${getVideoWidth()}%`}
        height="100%"
        config={{
          vimeo: {
            playerOptions: {
              responsive: 1,
            },
          },
          file: {
            attributes: {
              controlsList: 'nodownload',
            },
          },
        }}
        {...rest}
      />
    </div>
  )
}

export default VideoPlayer
